html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}



#root {
  font-family: sans-serif;
  background: "#27292D";
  margin: 0;
  padding: 0;
  overflow: hidden;
}


@media only screen and (max-width: 600px){
  html {
    font-size: 14px;
  }
}

